import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
//https://www.gatsbyjs.com/plugins/gatsby-source-custom-api-with-fetchoptions/?=json para menu??
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <Layout>
    <Helmet>
        <link rel="icon" href={'/ico32.jpg'} sizes="32x32" />
        <link rel="icon" href={'/ico192.jpg'} sizes="192x192" />
        <link rel="apple-touch-icon" href={'/ico180.jpg'} />
        <meta name="msapplication-TileImage" content={'/ico270.jpg'} />
    </Helmet>
    <Seo title={post.seo.title} description={post.seo.metaDesc} url={post.seo.canonical} />
      <article
        className="global-wrapper blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
      <h1 className="entry-title" itemProp="headline">{parse(post.title)}</h1>

        {!!post.content && (
          <section itemProp="articleBody">{parse(post.content)}</section>
        )}
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      seo {
        title
        canonical
        metaDesc
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
              )
            }
          }
        }
      }
    }
  }
`
